import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/MainPage/5/1.svg";
import img2 from "../../../assets/img/MainPage/5/2.svg";
import img3 from "../../../assets/img/MainPage/5/3.svg";
import classes from "./Five.module.scss";
import "./Five.scss";
import { useTranslation } from "react-i18next";

const Five = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.four}>
      <div className={classes.fourBody}>
        <div className={["container", classes.cont].join(" ")}>
          <div className={classes.fourTop}>
            <div className={classes.fourTopContent}>
              <h2
                className={[classes.fourTopContentTitle, "font-40"].join(" ")}
              >
                {t("main_5_title")}
              </h2>
              <p
                className={[
                  classes.fourTopContetText,
                  classes.mb60,
                  "font-20",
                  `m_five_text_mb_${t("lang")}`,
                ].join(" ")}
              >
                {t("main_5_text")}
              </p>

              <div
                className={[
                  classes.fourTopContetBtn,
                  `m_five_btn_mb_${t("lang")}`,
                ].join(" ")}
              >
                <Button second={"1"}> {t("learn_btn")}</Button>
              </div>
            </div>
          </div>
          <div className={classes.fourRow}>
            <div className={classes.fourRowColumn}>
              <div className={classes.fourRowColumnItem}>
                <div className={classes.fourRowColumnItemImg}>
                  <img src={img1} alt="" />
                </div>
                <h3
                  className={[
                    classes.fourRowColumnItemTitle,
                    "font-32-bold",
                  ].join(" ")}
                >
                  {t("main_5_item11")}
                </h3>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t("main_5_item12")}
                </p>
              </div>
            </div>
            <div className={classes.fourRowColumn}>
              <div className={classes.fourRowColumnItem}>
                <div className={classes.fourRowColumnItemImg}>
                  <img src={img2} alt="" />
                </div>
                <h3
                  className={[
                    classes.fourRowColumnItemTitle,
                    "font-32-bold",
                  ].join(" ")}
                >
                  {t("main_5_item21")}
                </h3>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t("main_5_item22")}
                </p>
              </div>
            </div>
            <div className={classes.fourRowColumn}>
              <div className={classes.fourRowColumnItem}>
                <div className={classes.fourRowColumnItemImg}>
                  <img src={img3} alt="" />
                </div>
                <h3
                  className={[
                    classes.fourRowColumnItemTitle,
                    "font-32-bold",
                  ].join(" ")}
                >
                  {t("main_5_item31")}
                </h3>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t("main_5_item32")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
