import React, { useEffect } from "react";
import classes from "./EvoTradePage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/EvoTradePage/Banner/Banner";
import WhyEvoTrade from "../../components/EvoTradePage/WhyEvoTrade/WhyEvoTrade";
import EvoPlate from "../../components/EvoTradePage/EvoPlate/EvoPlate";
import Easy from "../../components/MainPage/Easy/Easy";

const EvoTradePage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <WhyEvoTrade />
      <EvoPlate />
      <Easy />
      <Footer />
    </div>
  );
};

export default EvoTradePage;
