import React from "react";
import classes from "./Row.module.scss";
import { useTranslation } from "react-i18next";

const Row = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.row}>
      <div className={classes.rowBody}>
        <div className="container">
          <div className={classes.rowRow}>
            <div className={classes.rowColumn}>
              <div className={classes.rowColumnItem}>
                <div
                  className={[classes.rowColumnItemTitle, "font-24"].join(" ")}
                >
                  {t("products_3_item11")}
                </div>
                <div
                  className={[classes.rowColumnItemSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t("products_3_item12")}
                </div>
              </div>
            </div>
            <div className={classes.rowColumn}>
              <div className={classes.rowColumnItem}>
                <div
                  className={[classes.rowColumnItemTitle, "font-24"].join(" ")}
                >
                  {t("products_3_item21")}
                </div>
                <div
                  className={[classes.rowColumnItemSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t("products_3_item22")}
                </div>
              </div>
            </div>
            <div className={classes.rowColumn}>
              <div className={classes.rowColumnItem}>
                <div
                  className={[classes.rowColumnItemTitle, "font-24"].join(" ")}
                >
                  {t("products_3_item31")}
                </div>
                <div
                  className={[classes.rowColumnItemSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t("products_3_item32")}
                </div>
              </div>
            </div>
            <div className={classes.rowColumn}>
              <div className={classes.rowColumnItem}>
                <div
                  className={[classes.rowColumnItemTitle, "font-24"].join(" ")}
                >
                  {t("products_3_item41")}
                </div>
                <div
                  className={[classes.rowColumnItemSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t("products_3_item42")}
                </div>
              </div>
            </div>
            <div className={classes.rowColumn}>
              <div className={classes.rowColumnItem}>
                <div
                  className={[classes.rowColumnItemTitle, "font-24"].join(" ")}
                >
                  {t("products_3_item51")}
                </div>
                <div
                  className={[classes.rowColumnItemSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t("products_3_item52")}
                </div>
              </div>
            </div>
            <div className={classes.rowColumn}>
              <div className={classes.rowColumnItem}>
                <div
                  className={[classes.rowColumnItemTitle, "font-24"].join(" ")}
                >
                  {t("products_3_item61")}
                </div>
                <div
                  className={[classes.rowColumnItemSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t("products_3_item62")}
                </div>
              </div>
            </div>
            <div className={classes.rowColumn}>
              <div className={classes.rowColumnItem}>
                <div
                  className={[classes.rowColumnItemTitle, "font-24"].join(" ")}
                >
                  {t("products_3_item71")}
                </div>
                <div
                  className={[classes.rowColumnItemSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t("products_3_item72")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
