import React from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import Three from "../../components/ProductsPageComponents/Three/Three";
import First from "../../components/ProductsPageComponents/First/First";
import classes from "./ProductsPage.module.scss";
import Four from "../../components/ProductsPageComponents/Four/Four";
import Five from "../../components/ProductsPageComponents/Five/Five";
import Six from "../../components/ProductsPageComponents/Six/Six";
import Row from "../../components/ProductsPageComponents/Row/Row";
import Row2 from "../../components/ProductsPageComponents/Row2/Row2";
const ProductsPage = () => {
  return (
    <div className={classes.products}>
      <Header />
      <First />
      <Three />
      <Row />
      <Four />
      <Row2 />
      <Five />
      <Six />
      <Footer />
    </div>
  );
};

export default ProductsPage;
