import React, { useRef, useEffect, useState } from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

import img1 from "../../../assets/img/ProductsPage/1/planchet.png";
import img2 from "../../../assets/img/ProductsPage/1/11.png";

import "./First.scss";

const First = () => {
  const { t } = useTranslation();

  gsap.registerPlugin(ScrollTrigger);
  const contentRef = useRef(null);
  const peopleRef = useRef(null);
  const textRef = useRef(null);
  const planetRef = useRef(null);
  const lineRef = useRef(null);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.to(peopleRef.current, {
        opacity: 1,
        scale: 1,
        y: 0,
        duration: 1,
        delay: 1,
      });
      gsap.to(textRef.current, {
        opacity: 1,
        y: 0,
        duration: 1,
        delay: 1,
      });
      gsap.to(planetRef.current, {
        opacity: 1,
        duration: 3,
        scale: 1,
        delay: 1,
      });
      gsap.to(lineRef.current, {
        opacity: 1,
        duration: 2,
        scale: 1,
        delay: 1,
      });
    }
  }, []);

  return (
    <div className={classes.first} ref={contentRef}>
      <div className={classes.firstBody}>
        <div className="container">
          <div className={classes.firstWrapper}>
            <div>
              <div className={classes.text} ref={textRef}>
                <h2 className={[classes.firstTitle, "font-64"].join(" ")}>
                  {t("products_1_title")}
                </h2>

                <p className={[classes.firstText, "font-20"].join(" ")}>
                  {t("products_1_subtitle")}
                </p>

                <div className={classes.firstBtn}>
                  <Button>{t("start_btn")}</Button>
                </div>
                <div className={classes.planImg}>
                  {width > 468 && <img src={img1} alt="" />}
                  {width < 469 && <img src={img2} alt="" />}
                </div>
                {/* {width < 769 && (
                  <p className={[classes.firstText, "font-20"].join(" ")}>
                    Discover a world of trading opportunities on ProfitConnect.
                    Choose from a wide range of currency pairs, including
                    popular cryptocurrencies like Bitcoin and Litecoin. Take
                    advantage of advanced trading tools and low fees to enhance
                    your trading experience. Whether you're a beginner or an
                    experienced trader, ProfitConnect has the products and resources
                    you need to succeed in the fast-paced world of currency
                    trading.
                  </p>
                )} */}
              </div>
            </div>

            <div className={classes.firstPeople} ref={peopleRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
