import React, { useState } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Most.module.scss";
import { useTranslation } from "react-i18next";

const Most = () => {
  const { t } = useTranslation();
  const [spoilers, setSpoilers] = useState([
    {
      id: 1,
      title: "faq_item_1_1",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,

          text: "faq_item_1_2",
        },
      ],
    },
    {
      id: 2,
      title: "faq_item_2_1",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq_item_2_2`,
        },
      ],
    },
    {
      id: 3,
      title: "faq_item_3_1",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq_item_3_2`,
          listCircle: [
            `faq_item_3_3`,
            `faq_item_3_4`,
            `faq_item_3_5`,
            `faq_item_3_6`,
          ],
        },
      ],
    },
    {
      id: 4,
      title: "faq_item_4_1",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq_item_4_2`,
        },
      ],
    },
    {
      id: 5,
      title: "faq_item_5_1",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq_item_5_2`,
          listNumbers: [
            `faq_item_5_3`,
            `faq_item_5_4`,
            `faq_item_5_5`,
            `faq_item_5_6`,
            `faq_item_5_7`,
          ],
        },
      ],
    },
  ]);

  return (
    <div className={classes.most}>
      <div className={classes.mostBody}>
        <div className="container">
          <h2 className={[classes.mostTitle, "font-64"].join(" ")}>
            {t("faq_1_title")}
          </h2>

          {spoilers.map((i) => {
            return (
              <div key={i.id} className={classes.mostSpoiler}>
                <p
                  className={
                    i.active
                      ? [
                          classes.mostSpoilerTitle,
                          "font-32-600",
                          classes.active,
                        ].join(" ")
                      : [classes.mostSpoilerTitle, "font-32-600"].join(" ")
                  }
                  onClick={() => {
                    setSpoilers(
                      spoilers.map((curI) => {
                        if (curI.id === i.id) {
                          return { ...curI, active: !curI.active };
                        }
                        return { ...curI, active: false };
                      })
                    );
                  }}
                >
                  {t(i.title)}
                </p>
                {i.contents.map((subI) => {
                  return (
                    <div
                      key={subI.id}
                      className={
                        i.active
                          ? subI.isStart
                            ? [
                                classes.mostSpoilerContent,
                                classes.active,
                                classes.star,
                                "font-20",
                              ].join(" ")
                            : [
                                classes.mostSpoilerContent,
                                classes.active,
                                "font-20",
                              ].join(" ")
                          : classes.mostSpoilerContent
                      }
                    >
                      {t(subI.text)}
                      {subI.listNumbers && (
                        <ol>
                          {subI.listNumbers.map((listNum) => {
                            return <li className="font-20">{t(listNum)}</li>;
                          })}
                        </ol>
                      )}
                      {subI.listCircle && (
                        <ul style={{ listStyleType: "circle" }}>
                          {subI.listCircle.map((listNum) => {
                            return <li className="font-20">{t(listNum)}</li>;
                          })}
                        </ul>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Most;
