import React from "react";
import classes from "./TheFeatures.module.scss";
import icon_left_01 from "../../../assets/img/AboutPage/TheFeatures/icon_left_01.png";
import icon_left_02 from "../../../assets/img/AboutPage/TheFeatures/icon_left_02.png";
import icon_left_03 from "../../../assets/img/AboutPage/TheFeatures/icon_left_03.png";
import icon_right_01 from "../../../assets/img/AboutPage/TheFeatures/icon_right_01.png";
import icon_right_02 from "../../../assets/img/AboutPage/TheFeatures/icon_right_01.png";
import icon_right_03 from "../../../assets/img/AboutPage/TheFeatures/icon_right_01.png";

import { useTranslation } from "react-i18next";

const TheFeatures = () => {
  const { t } = useTranslation();

  const leftCards = [
    {
      icon: icon_left_01,
      title: t("about_2_11"),
      text: t("about_2_12"),
    },
    {
      icon: icon_left_02,
      title: t("about_2_31"),
      text: t("about_2_32"),
    },
    {
      icon: icon_left_03,
      title: t("about_2_51"),
      text: t("about_2_52"),
    },
  ];
  const rightCards = [
    {
      icon: icon_right_01,
      title: t("about_2_21"),
      text: t("about_2_22"),
    },
    {
      icon: icon_right_02,
      title: t("about_2_41"),
      text: t("about_2_42"),
    },
    {
      icon: icon_right_03,
      title: t("about_2_61"),
      text: t("about_2_62"),
    },
  ];

  return (
    <section className="container">
      <div className={classes.theFeatures}>
        <h2 className={classes.title}>{t("about_2_title")}</h2>
        <div className={classes.content}>
          <div className={[classes.col, classes.col_01].join(" ")}>
            {leftCards.map((card, index) => (
              <div key={index} className={classes.card}>
                <img src={card.icon} alt={card.title} />
                <h3 className={classes.cardTitle}>{card.title}</h3>
                <p className={classes.cardText}>{card.text}</p>
              </div>
            ))}
          </div>
          <div className={[classes.col, classes.col_02].join(" ")}>
            {rightCards.map((card, index) => (
              <div key={index} className={classes.card}>
                <img src={card.icon} alt={card.title} />
                <h3 className={classes.cardTitle}>{card.title}</h3>
                <p className={classes.cardText}>{card.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TheFeatures;
