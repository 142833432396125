import React, { useRef, useEffect, useState } from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";

import img1 from "../../../assets/img/MainPage/1/planchet.png";
import img2 from "../../../assets/img/MainPage/1/11.png";
import { useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);
  const contentRef = useRef(null);
  const peopleRef = useRef(null);
  const textRef = useRef(null);
  const planetRef = useRef(null);
  const lineRef = useRef(null);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.to(peopleRef.current, {
        opacity: 1,
        scale: 1,
        y: 0,
        duration: 1,
        delay: 1,
      });
      gsap.to(textRef.current, {
        opacity: 1,
        y: 0,
        duration: 1,
        delay: 1,
      });
      gsap.to(planetRef.current, {
        opacity: 1,
        duration: 3,
        scale: 1,
        delay: 1,
      });
      gsap.to(lineRef.current, {
        opacity: 1,
        duration: 2,
        scale: 1,
        delay: 1,
      });
    }
  }, []);

  return (
    <div className={classes.first} ref={contentRef}>
      <div className={classes.firstBody}>
        <div className="container">
          <div className={classes.firstWrapper}>
            <div>
              <div className={classes.text} ref={textRef}>
                <h1 className={[classes.firstTitle, "font-64"].join(" ")}>
                  {t("main_1_title")}
                </h1>
                <h2
                  className={[classes.firstSubtitle, "font-32-banner"].join(
                    " "
                  )}
                >
                  {t("main_1_subtitle")}
                </h2>
                {width > 768 && (
                  <p className={[classes.firstText, "font-20"].join(" ")}>
                    {t("main_1_text")}
                  </p>
                )}

                <div className={classes.firstBtn}>
                  <Button> {t("start_btn")}</Button>
                </div>
                <div className={classes.planImg}>
                  {width > 468 && <img src={img1} alt="" />}
                  {width < 469 && <img src={img2} alt="" />}
                </div>
                {width < 769 && (
                  <p className={[classes.firstText, "font-20"].join(" ")}>
                    {t("main_1_text")}
                  </p>
                )}
              </div>
            </div>

            <div className={classes.firstPlanet} ref={planetRef}></div>
            <div className={classes.firstPeople} ref={peopleRef}></div>
            <div className={classes.firstLine} ref={lineRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
