import React from "react";
import Banner from "../../components/AboutPage/Banner/Banner";
import TheFeatures from "../../components/AboutPage/TheFeatures/TheFeatures";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";

const AboutPage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <TheFeatures />
      <Footer />
    </div>
  );
};

export default AboutPage;
