import React from "react";
import classes from "./EvoPlate.module.scss";
import evo_plate_img from "../../../assets/img/EvoTradePage/evo_plate.png";
import evo_plate_logo from "../../../assets/img/EvoTradePage/evo_plate_logo.png";

import { Trans, useTranslation } from "react-i18next";

const EvoPlate = () => {
  const { t } = useTranslation();
  return (
    <section className="container">
      <div className={classes.evoPlate}>
        <img className={classes.logo} src={evo_plate_logo} alt="" />
        <p className={classes.text}>
          <Trans>{t("evotrade_3_text")}</Trans>
        </p>
        <img className={classes.image} src={evo_plate_img} alt="" />
      </div>
    </section>
  );
};

export default EvoPlate;
