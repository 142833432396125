import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./components/GlobalComponents/Router/AppRouter";
import './App.scss';
import { useFonts } from 'expo-font';
import { IMAGES } from "./Images";
import Loader from "./components/GlobalComponents/Loader/Loader";


function App() {

  const [imgsLoaded, setImgsLoaded] = useState(false);

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.url;
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image.url);
          }, 2);

        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(IMAGES.map((image) => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch((err) => console.log("Failed to load images", err));
  }, []);
  return (
    <div >
      <Loader hide={imgsLoaded} />
      {imgsLoaded &&
        <BrowserRouter>

          {/* <div className={`preloader ${!isLoading && 'hidePreloader'}`}>
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div> */}
          <Router />
        </BrowserRouter>
      }

    </div>
  );
}

export default App;
