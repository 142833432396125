import React, { useRef, useEffect } from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import First from "../../components/MainPage/First/First";
import Second from "../../components/MainPage/Second/Second";
import Three from "../../components/MainPage/Three/Three";
import Four from "../../components/MainPage/Four/Four";
import Five from "../../components/MainPage/Five/Five";
import Six from "../../components/MainPage/Six/Six";
import Seven from "../../components/MainPage/Seven/Seven";
import Have from "../../components/MainPage/Have/Have";
import Easy from "../../components/MainPage/Easy/Easy";

const MainPage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <First />
      <Second />
      <Three />
      <Four />
      <Five />
      <Six />
      <Have />
      <Seven />
      <Easy />
      <Footer />
    </div>
  );
};

export default MainPage;
