import React from "react";
import classes from "./WhyEvoTrade.module.scss";
import icon_01 from "../../../assets/img/EvoTradePage/WhyEvoTrade/icon_01.svg";
import icon_02 from "../../../assets/img/EvoTradePage/WhyEvoTrade/icon_02.svg";
import icon_03 from "../../../assets/img/EvoTradePage/WhyEvoTrade/icon_03.svg";
import icon_04 from "../../../assets/img/EvoTradePage/WhyEvoTrade/icon_04.svg";
import icon_05 from "../../../assets/img/EvoTradePage/WhyEvoTrade/icon_05.svg";
import icon_06 from "../../../assets/img/EvoTradePage/WhyEvoTrade/icon_06.svg";
import icon_07 from "../../../assets/img/EvoTradePage/WhyEvoTrade/icon_07.svg";
import icon_08 from "../../../assets/img/EvoTradePage/WhyEvoTrade/icon_08.svg";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const cards = [
  {
    icon: icon_01,
    text: "evotrade_2_item1",
  },
  {
    icon: icon_02,
    text: "evotrade_2_item2",
  },
  {
    icon: icon_03,
    text: "evotrade_2_item3",
  },
  {
    icon: icon_04,
    text: "evotrade_2_item4",
  },
  {
    icon: icon_05,
    text: "evotrade_2_item5",
  },
  {
    icon: icon_06,
    text: "evotrade_2_item6",
  },
  {
    icon: icon_07,
    text: "evotrade_2_item7",
  },
  {
    icon: icon_08,
    text: "evotrade_2_item8",
  },
];

const WhyEvoTrade = () => {
  const { t } = useTranslation();

  return (
    <section className="container">
      <div className={classes.whyEvoTrade}>
        <h2 className={classes.title}>{t("evotrade_2_title")}</h2>
        <div className={classes.cards}>
          {cards.map((card, index) => (
            <div className={classes.card} key={index}>
              <img src={card.icon} alt={card.text} />
              <p className={classes.text}>
                <Trans>{t(card.text)}</Trans>
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyEvoTrade;
