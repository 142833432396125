import logo from './assets/img/header/logo.png';

import img1 from './assets/img/AboutPage/banner_01.png';
import img2 from './assets/img/AboutPage/banner_02.png';

import img3 from './assets/img/AccountTypes/banner.png';

import img4 from './assets/img/EvoTradePage/banner.png';

import img5 from './assets/img/MainPage/1/11.png';
import img6 from './assets/img/MainPage/1/line.png';
import img7 from './assets/img/MainPage/1/people.png';
import img8 from './assets/img/MainPage/1/planchet.png';
import img9 from './assets/img/MainPage/1/planet.png';

import img10 from './assets/img/ProductsPage/1/1.png';
import img11 from './assets/img/ProductsPage/1/11.png';
import img12 from './assets/img/ProductsPage/1/planchet.png';







export const IMAGES = [
    {
        id: 0,
        url:
            img1
    },
    {
        id: 1,
        url:
            img2
    },
    {
        id: 2,
        url:
            img3
    },
    {
        id: 3,
        url:
            img4
    },
    {
        id: 4,
        url:
            img5
    },
    {
        id: 5,
        url:
            img6
    },
    {
        id: 6,
        url:
            img7
    },
    {
        id: 7,
        url:
            img8
    },
    {
        id: 8,
        url:
            img9
    },
    {
        id: 9,
        url:
            img10
    },
    {
        id: 10,
        url:
            img11
    },
    {
        id: 11,
        url:
            img12
    },
    {
        id: 12,
        url:
            logo
    },






]
