import React, { useEffect } from "react";
import classes from "./AccountTypesPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/AccountTypes/Banner/Banner";
import Cards from "../../components/AccountTypes/Cards/Cards";
import Easy from "../../components/MainPage/Easy/Easy";

const AccountTypesPage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <Cards />
      <Easy />
      <Footer />
    </div>
  );
};

export default AccountTypesPage;
