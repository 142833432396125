import React from "react";
import classes from "./Second.module.scss";
import { useTranslation } from "react-i18next";

const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.second}>
      <div className={classes.secondBody}>
        <div className="container">
          <div className={classes.secondRow}>
            <div className={classes.secondLeft}>
              <h2 className={[classes.secondLeftTitle, "font-40"].join(" ")}>
                {t("main_2_title")}
              </h2>
              <p className={[classes.secondLeftSubtitle, "font-48"].join(" ")}>
                {t("main_2_subtitle")}
              </p>
            </div>
            <ul className={classes.secondRowColumn}>
              <li
                className={[classes.secondRowColumnItem, "font-24"].join(" ")}
              >
                {t("main_2_item1")}
              </li>
              <li
                className={[classes.secondRowColumnItem, "font-24"].join(" ")}
              >
                {t("main_2_item2")}
              </li>
              <li
                className={[classes.secondRowColumnItem, "font-24"].join(" ")}
              >
                {t("main_2_item3")}
              </li>
              <li
                className={[classes.secondRowColumnItem, "font-24"].join(" ")}
              >
                {t("main_2_item4")}
              </li>
              <li
                className={[classes.secondRowColumnItem, "font-24"].join(" ")}
              >
                {t("main_2_item5")}
              </li>
            </ul>
            <ul className={classes.secondRowColumn}>
              <li
                className={[classes.secondRowColumnItem, "font-24"].join(" ")}
              >
                {t("main_2_item6")}
              </li>
              <li
                className={[classes.secondRowColumnItem, "font-24"].join(" ")}
              >
                {t("main_2_item7")}
              </li>
              <li
                className={[classes.secondRowColumnItem, "font-24"].join(" ")}
              >
                {t("main_2_item8")}
              </li>
              <li
                className={[classes.secondRowColumnItem, "font-24"].join(" ")}
              >
                {t("main_2_item9")}
              </li>
              <li
                className={[classes.secondRowColumnItem, "font-24"].join(" ")}
              >
                {t("main_2_item10")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
