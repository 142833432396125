import React from "react";
import classes from "./Button.module.scss";
const Button = (props) => {
  return (
    <div>
      {props.second && (
        <a href="https://dash.profit-connect.pro/signup" className={[classes.button, classes.button1].join(" ")}>
          <span>{props.children}</span>
        </a>
      )}
      {!props.second && (
        <a href="https://dash.profit-connect.pro/signup" className={classes.button}>
          <span>{props.children}</span>
        </a>
      )}
    </div>
  );
};

export default Button;
