import React, { useRef, useEffect, useState } from "react";
import classes from "./Easy.module.scss";
import img1 from "../../../assets/img/MainPage/9/1.png";
import Button from "../../GlobalComponents/Button/Button";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";

const Easy = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  const contentRef = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(ref1.current, {
        x: 0,
        scrollTrigger: {
          trigger: contentRef.current,
          start: "top 1000px",
          end: "top 100px",
          scrub: 3,
        },
      });
      gsap.timeline().to(ref2.current, {
        x: 0,
        scrollTrigger: {
          trigger: contentRef.current,
          start: "top 1000px",
          end: "top 100px",
          scrub: 3,
        },
      });
      gsap.timeline().to(ref3.current, {
        x: 0,
        scrollTrigger: {
          trigger: contentRef.current,
          start: "top 1000px",
          end: "top 100px",
          scrub: 3,
        },
      });
    }
  }, []);

  return (
    <div className={classes.easy} ref={contentRef}>
      <div className={classes.easyBody}>
        <div className="container">
          <div className={classes.easyRow}>
            <div className={classes.easyRowLeft}>
              <div className={[classes.easyRowLeftTitle, "font-64"].join(" ")}>
                {t("main_9_title")}
              </div>
              <div
                className={[classes.easyRowLeftSubtitle, "font-24"].join(" ")}
              >
                {t("main_9_subtitle")}
              </div>
              {width > 1220 && (
                <div className={classes.easyRowLeftImg}>
                  <img src={img1} alt="" />
                </div>
              )}
            </div>
            <div className={classes.easyRowRight}>
              <div className={classes.easyRowRightRow}>
                <div className={classes.easyRowRightRowColumn} ref={ref1}>
                  <div
                    className={[
                      classes.easyRowRightRowColumnNumber,
                      "font-32-bold",
                    ].join(" ")}
                  >
                    1
                  </div>
                  <div
                    className={[
                      classes.easyRowRightRowColumnTitle,
                      "font-32-bold",
                    ].join(" ")}
                  >
                    {t("main_9_item11")}
                  </div>
                  <div
                    className={[
                      classes.easyRowRightRowColumneasyRowRightRowColumnSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_9_item12")}
                  </div>
                </div>
                <div className={classes.easyRowRightRowColumn} ref={ref2}>
                  <div
                    className={[
                      classes.easyRowRightRowColumnNumber,
                      "font-32-bold",
                    ].join(" ")}
                  >
                    2
                  </div>
                  <div
                    className={[
                      classes.easyRowRightRowColumnTitle,
                      "font-32-bold",
                    ].join(" ")}
                  >
                    {t("main_9_item21")}
                  </div>
                  <div
                    className={[
                      classes.easyRowRightRowColumneasyRowRightRowColumnSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_9_item22")}
                  </div>
                </div>
                <div className={classes.easyRowRightRowColumn} ref={ref3}>
                  <div
                    className={[
                      classes.easyRowRightRowColumnNumber,
                      "font-32-bold",
                    ].join(" ")}
                  >
                    3
                  </div>
                  <div
                    className={[
                      classes.easyRowRightRowColumnTitle,
                      "font-32-bold",
                    ].join(" ")}
                  >
                    {t("main_9_item31")}
                  </div>
                  <div
                    className={[
                      classes.easyRowRightRowColumneasyRowRightRowColumnSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_9_item32")}
                  </div>
                  <div className={classes.easyRowRightBtn}>
                    <Button> {t("start_btn")}</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {width < 1221 && (
            <div className={classes.mobImg}>
              <img src={img1} alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Easy;
