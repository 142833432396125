import React from "react";
import classes from "./FaqPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Most from "../../components/FaqPage/Most/Most";

const FaqPage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Most />
      <Footer />
    </div>
  );
};

export default FaqPage;
