import React from "react";
import classes from "./Banner.module.scss";
import evo_banner from "../../../assets/img/EvoTradePage/banner.png";
import Button from "../../GlobalComponents/Button/Button";

import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <section className={classes.banner}>
      <div className="container">
        <h1 className={[classes.title, "font-64"].join(" ")}>
          {t("evotrade_1_title")}
        </h1>
        <p className={[classes.text, "font-20"].join(" ")}>
          {t("evotrade_1_subtitle")}
        </p>
        <div className={classes.btn}>
          <Button>{t("start_btn")}</Button>
        </div>
      </div>
      <img
        src={evo_banner}
        alt="Marvelous Trading Starts with the Best Platform !"
      />
    </section>
  );
};

export default Banner;
