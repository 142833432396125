import React from "react";
import classes from "./Banner.module.scss";
import about_img_01 from "../../../assets/img/AboutPage/banner_01.png";
import about_img_02 from "../../../assets/img/AboutPage/banner_02.png";

import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className="container">
      <div className={classes.banner}>
        <div className={classes.col}>
          <div className={classes.colText}>
            <div className={classes.title}>{t("about_1_title")}</div>
            <div className={classes.subtitle}>{t("about_1_subtitle")}</div>
            <div className={classes.text}>
              <p>{t("about_1_item1")}</p>
              <p>{t("about_1_item2")}</p>
            </div>
          </div>
          <div className={classes.colImg}>
            <img className={classes.img_01} src={about_img_01} alt="" />
          </div>
        </div>
        <div className={classes.col}>
          <div className={classes.colText}>
            <div className={classes.text}>
              <p>{t("about_1_item3")}</p>
              <p>{t("about_1_item4")}</p>
            </div>
          </div>
          <div className={classes.colImg}>
            <img className={classes.img_02} src={about_img_02} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
