import React from "react";
import classes from "./Banner.module.scss";
import accounts_banner from "../../../assets/img/AccountTypes/banner.png";

import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <section className={classes.banner}>
      <div className="container">
        <div className={classes.content}>
          <h1 className={[classes.title, "font-64"].join(" ")}>
            {t("accounts_1_title")}
          </h1>
          <h3 className={classes.text}>{t("accounts_1_subtitle")}</h3>
          <img
            src={accounts_banner}
            alt="The Variety of Account Types we Offer"
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
