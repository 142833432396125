import React from "react";
import classes from "./Cards.module.scss";
import Button from "../../GlobalComponents/Button/Button";

import { Trans, useTranslation } from "react-i18next";

const Cards = () => {
  const { t } = useTranslation();

  const cards = [
    {
      id: 1,
      title: t("accounts_2_item1_name1"),
      content: [
        {
          title: t("accounts_2_item1_1_1"),
          list: ["25 USD", "25 EUR", "100 AED"],
        },
        {
          title: t("accounts_2_item1_2_1"),
          list: ["USD, EUR, AED"],
        },
        {
          title: t("accounts_2_item1_3_1"),
          list: [
            t("accounts_2_item1_3_2") + " - 5",
            t("accounts_2_item1_3_3") + " - 3",
            t("accounts_2_item1_3_4") + " - 7",
            t("accounts_2_item1_3_5") + " - 24",
            t("accounts_2_item1_3_6") + " - 11",
          ],
        },
        {
          title: t("accounts_2_item1_4_1"),
          list: ["1:500"],
        },
        {
          title: t("accounts_2_item1_5_1"),
          list: [`${t("accounts_2_item1_5_2")} 0.5`],
        },
        {
          title: t("accounts_2_item1_6_1"),
          list: [
            `${t("accounts_2_item1_6_2")} 0.02 USD ${t(
              "accounts_2_item1_6_3"
            )}`,
          ],
        },
        {
          title: t("accounts_2_item1_7_1"),
          list: ["0.01"],
        },
        {
          title: t("accounts_2_item1_8_1"),
          list: ["100"],
        },
      ],
    },
    {
      id: 1,
      title: t("accounts_2_item1_name2"),
      content: [
        {
          title: t("accounts_2_item1_1_1"),
          list: ["25 USD", "25 EUR", "100 AED"],
        },
        {
          title: t("accounts_2_item1_2_1"),
          list: ["USD, EUR, AED"],
        },
        {
          title: t("accounts_2_item1_3_1"),
          list: [
            t("accounts_2_item1_3_2") + " - 4",
            t("accounts_2_item1_3_3") + " - 3",
            t("accounts_2_item1_3_4") + " - 7",
            t("accounts_2_item1_3_5") + " - 3",
          ],
        },
        {
          title: t("accounts_2_item1_4_1"),
          list: ["1:500"],
        },
        {
          title: t("accounts_2_item1_5_1"),
          list: [`${t("accounts_2_item1_5_2")} 0.5`],
        },
        {
          title: t("accounts_2_item1_6_1"),
          list: [
            `${t("accounts_2_item1_6_2")} 0.02 USD ${t(
              "accounts_2_item1_6_3"
            )}`,
          ],
        },
        {
          title: t("accounts_2_item1_7_1"),
          list: ["0.01"],
        },
        {
          title: t("accounts_2_item1_8_1"),
          list: ["100"],
        },
      ],
    },
  ];

  return (
    <section className="container">
      <div className={classes.cardsWr}>
        <div className={classes.cards}>
          {cards.map((card) => (
            <div key={card.id} className={classes.card}>
              <div className={classes.cardHeader}>
                <h2 className={classes.cardHeaderText}>{card.title}</h2>
                <div className={classes.cardHeaderLogo}></div>
              </div>
              <div className={classes.cardContent}>
                {card.content.map((row, rowIndex) => (
                  <div className={classes.row} key={rowIndex}>
                    <h3 className={classes.rowLeft}>{row.title}</h3>
                    <ul className={classes.rowRight}>
                      {row.list.map((listItem, listItemIndex) => (
                        <li key={listItemIndex}>{listItem}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className={classes.cardBtn}>
                <a href="#" className={classes.btn}>
                  {t("open_btn")}
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.bottomText}>
          <Trans>{t("accounts_2_text")}</Trans>
        </div>
      </div>
    </section>
  );
};

export default Cards;
