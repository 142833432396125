
import AboutPage from "../pages/AboutPage/AboutPage";
import FaqPage from "../pages/FaqPage/FaqPage";
import ProductsPage from "../pages/ProductsPage/ProductsPage";
import MainPage from "../pages/MainPage/MainPage";
import AccountTypesPage from "../pages/AccountTypesPage/AccountTypesPage";
import EvoTradePage from "../pages/EvoTradePage/EvoTradePage";

export const routes = [
    { path: '/products', element: ProductsPage },
    { path: '/evo-trade', element: EvoTradePage },
    { path: '/account-types', element: AccountTypesPage },
    { path: '*', element: MainPage },
    { path: '/faq', element: FaqPage },
    { path: '/about', element: AboutPage },

];

export const headerRoutes = [
    { id: 1, path: '/products', text: "header_item_1" },
    { id: 2, path: '/evo-trade', text: "header_item_2" },
    { id: 3, path: '/account-types', text: "header_item_3" },
    { id: 4, path: '/faq', text: "header_item_4" },
    { id: 5, path: '/about', text: "header_item_5" },
]