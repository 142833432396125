import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/MainPage/4/1.svg";
import img2 from "../../../assets/img/MainPage/4/2.svg";
import img3 from "../../../assets/img/MainPage/4/3.svg";
import classes from "./Four.module.scss";
import "../../MainPage/Four/Four.scss";
import { useTranslation } from "react-i18next";

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.four}>
      <div className={classes.fourBody}>
        <div className={["container", classes.cont].join(" ")}>
          <div className={classes.fourTop}>
            <div className={classes.fourTopContent}>
              <h2
                className={[classes.fourTopContentTitle, "font-40"].join(" ")}
              >
                {t("products_4_title1")}
              </h2>

              <p
                className={[
                  classes.fourTopContetText,
                  classes.mb60,
                  "font-20",
                  `m_f_text_mb_${t("lang")}`,
                ].join(" ")}
              >
                {t("products_4_text")}
              </p>
              <div
                className={[
                  classes.fourTopContetBtn,
                  `m_f_btn_mb_${t("lang")}`,
                ].join(" ")}
              >
                <Button> {t("start_btn")}</Button>
              </div>
              <p
                className={[classes.fourTopContentBotP, "font-32-600"].join(
                  " "
                )}
              >
                {t("products_4_bot_text")}
              </p>
            </div>
          </div>
          <div className={classes.fourRow}>
            <div className={classes.fourRowColumn}>
              <div className={classes.fourRowColumnItem}>
                <div className={classes.fourRowColumnItemImg}>
                  <img src={img1} alt="" />
                </div>
                <h3
                  className={[
                    classes.fourRowColumnItemTitle,
                    "font-32-bold",
                  ].join(" ")}
                >
                  {t("products_4_item11")}
                </h3>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item12")}
                </p>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item13")}
                </p>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item14")}
                </p>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item15")}
                </p>
              </div>
            </div>
            <div className={classes.fourRowColumn}>
              <div className={classes.fourRowColumnItem}>
                <div className={classes.fourRowColumnItemImg}>
                  <img src={img2} alt="" />
                </div>
                <h3
                  className={[
                    classes.fourRowColumnItemTitle,
                    "font-32-bold",
                  ].join(" ")}
                >
                  {t("products_4_item21")}
                </h3>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item22")}
                </p>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item23")}
                </p>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item24")}
                </p>
              </div>
            </div>
            <div className={classes.fourRowColumn}>
              <div className={classes.fourRowColumnItem}>
                <div className={classes.fourRowColumnItemImg}>
                  <img src={img3} alt="" />
                </div>
                <h3
                  className={[
                    classes.fourRowColumnItemTitle,
                    "font-32-bold",
                  ].join(" ")}
                >
                  {t("products_4_item31")}
                </h3>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item32")}
                </p>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item33")}
                </p>
                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item34")}
                </p>

                <p
                  className={[
                    classes.fourRowColumnItemSubtitle,
                    "font-20",
                    "uppercase",
                  ].join(" ")}
                >
                  {t("products_4_item35")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
