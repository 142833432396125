import React, { useRef, useEffect, useState } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Three.module.scss";
import img1 from "../../../assets/img/MainPage/3/mob.png";

import { useTranslation } from "react-i18next";

const Three = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={classes.three}>
      <div className={classes.threeBody}>
        <div className="container">
          <div className={classes.threeWrapper}>
            <div className={classes.threeImg}></div>
            <div className={classes.threeContent}>
              <div className={[classes.threeContentTitle, "font-40"].join(" ")}>
                {t("products_2_title")}
              </div>
              <div className={[classes.threeContentText, "font-20"].join(" ")}>
                {t("products_2_subtitle")}
              </div>
              <div className={classes.threeContentBtn}>
                <Button> {t("start_btn")}</Button>
              </div>
              {width < 1221 && (
                <div className={classes.mobImg}>
                  <img src={img1} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
