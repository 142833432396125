import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Have.module.scss";

import { useTranslation } from "react-i18next";

const Have = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.have}>
      <div className={classes.haveBody}>
        <div className="container">
          <div className={classes.haveRow}>
            <div className={classes.haveRowLeft}>
              <div className={[classes.haveRowLeftTitle, "font-48"].join(" ")}>
                {t("main_7_title")}
              </div>
              <div className={[classes.haveRowLeftText, "font-20"].join(" ")}>
                {t("main_7_subtitle")}
              </div>
              <div className={classes.haveRowLeftBtn}>
                <Button second={"1"}> {t("see_btn")}</Button>
              </div>
            </div>
            <div className={classes.haveRowRight}>
              <div className={classes.haveRowRightImg}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Have;
