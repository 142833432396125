import React from "react";
import classes from "./Seven.module.scss";
import img1 from "../../../assets/img/MainPage/8/1.svg";
import img2 from "../../../assets/img/MainPage/8/2.svg";
import img3 from "../../../assets/img/MainPage/8/3.svg";

import { useTranslation } from "react-i18next";

const Seven = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.seven}>
      <div className={classes.sevenBody}>
        <div className="container">
          <div className={[classes.sevenTitle, "font-48"].join(" ")}>
            {t("main_8_title")}
          </div>
          <div className={classes.sevenRow}>
            <div className={classes.sevenRowColumn}>
              <div className={classes.sevenRowColumnItem}>
                <div className={classes.sevenRowColumnItemImg}>
                  <img src={img1} alt="" />
                </div>
                <div
                  className={[
                    classes.sevenRowColumnItemTitle,
                    "font-32-bold",
                  ].join(" ")}
                >
                  {t("main_8_item11")}
                </div>
                <div
                  className={[
                    classes.sevenRowColumnItemSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t("main_8_item12")}
                </div>
              </div>
            </div>
            <div className={classes.sevenRowColumn}>
              <div className={classes.sevenRowColumnItem}>
                <div className={classes.sevenRowColumnItemImg}>
                  <img src={img2} alt="" />
                </div>
                <div
                  className={[
                    classes.sevenRowColumnItemTitle,
                    "font-32-bold",
                  ].join(" ")}
                >
                  {t("main_8_item21")}
                </div>
                <div
                  className={[
                    classes.sevenRowColumnItemSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t("main_8_item22")}
                </div>
              </div>
            </div>
            <div className={classes.sevenRowColumn}>
              <div className={classes.sevenRowColumnItem}>
                <div className={classes.sevenRowColumnItemImg}>
                  <img src={img3} alt="" />
                </div>
                <div
                  className={[
                    classes.sevenRowColumnItemTitle,
                    "font-32-bold",
                  ].join(" ")}
                >
                  {t("main_8_item31")}
                </div>
                <div
                  className={[
                    classes.sevenRowColumnItemSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t("main_8_item32")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seven;
